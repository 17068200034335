import styles from "./Menu.module.scss";
import { useIsMobile } from "../hooks/useIsMobile.js";

const Menu = () => {
  const isMobile = useIsMobile();
  const instagram = "/assets/images/instagram.png";
  const tiktok = "/assets/images/tiktok.png";

  return (
    <>
      {!isMobile ? (
        // Desktop Version
        <div className={styles.menuWrapper}>
          <div className={styles.menuLinks}>
            <a href="/">Home</a>
            <a href="/user_details">User Details</a>
            <a href="/order_online">Order Online</a>
            <a href="/special_events">Special Events</a>
            <a href="/gallery">Our Product</a>
            <a href="/news">News</a>
            <a href="/landing">Promotions</a>
            <a href="/contact">Contact Us</a>
          </div>
        </div>
      ) : (
        // Mobile Version
        <div className={styles.mobileWrapper}>
          <div className={styles.header}>POKINPOKIN</div>
          <div className={styles.mobileLinks}>
            <a href="/">Home</a>
            <a href="/user_details">User Details</a>
            <a href="/order_online">Order Online</a>
            <a href="/special_events">Special Events</a>
            <a href="/gallery">Our Products</a>
            <a href="/news">News</a>
            <a href="/landing">Promotions</a>
            <a href="/contact">Contact Us</a>
          </div>
          <div className={styles.socials}>
            <div
              className={styles.tiktok}
              onClick={() =>
                window.open("https://www.tiktok.com/@pokinpokinucm")
              }
            >
              <img src={tiktok} alt="tiktok" />
            </div>
            <div
              className={styles.instagram}
              onClick={() =>
                window.open("https://www.instagram.com/pokinpokin_ucm/")
              }
            >
              <img src={instagram} alt="instagram" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
