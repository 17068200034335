import { useEffect } from "react";
import { CRMFRONT_URL } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie"; // 引入 js-cookie

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = Cookies.get("token"); // 从 cookie 中获取 token

    // 检查是否在 /login/success 页面，如果是则不跳转，等待令牌交换
    if (location.pathname === "/login/success") {
      return; // 在成功页面，不做任何处理
    }

    if (token) {
      // 如果用户已经登录，直接跳转到主页或其他目标页面
      navigate("/user_details");
    } else {
      // 否则跳转到项目B进行登录
      const botId = 241;
      if (!window.location.href.includes(CRMFRONT_URL)) {
        // 避免重复跳转
        window.location.href = `${CRMFRONT_URL}/login?botId=${botId}`;
      }
    }
  }, [navigate, location]);

  return <></>;
};

export default Login;
