import { Scroll } from "../../components"

import {
    Location,
    Message,
} from "./components"

const Contact = () => {
    return (
        <>
        <Location />
        <Scroll />
        <Message />
        </>
    );
};

export default Contact;