import Button from './Button';
import Contact from './Contact';
import styles from './Banner.module.scss';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useState } from 'react';
import { Link } from "react-router-dom";
const Banner = () => {
    const [showContact, setShowContact] = useState(false);
    const handleContact = () => {
        setShowContact(!showContact);
        if (!showContact) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }
    }
    return (
        <div className={styles.banner}>
            <div className={styles.bannerText}>
                <h1>Catch the Fun,<br></br>Embrace the<br></br>Cuteness!</h1>
                <div className={styles.bannerTitle}>
                    <p>Grand Opening Special Offers</p>
                </div>
                <div className={styles.bannerButtons}>
                    <a href="tel:+3655500060">
                        <Button format="white">(905) 869-3098</Button>
                    </a>
                    {
                        useIsMobile() &&
                        <Button format="white"
                            onClick={handleContact}
                        >Contact Us</Button>
                    }
                </div>
                {
                    showContact &&
                    <div>
                        <div className={styles.bannerBlurBackground}></div>
                        <div className={styles.bannerPopup}>
                            <div className={styles.bannerBackground}></div>
                            <div className={styles.bannerContact}>
                                <Contact />
                                <div className={styles.bannerCross}
                                    onClick={handleContact}
                                >x</div>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.bannerWelcome}>
                    <p>
                    To celebrate the grand opening of our new store, we're offering a special 4% discount on token purchases! Act quickly to seize this fantastic opportunity and enjoy more fun and savings!
                    </p>
                </div>
                <div className={styles.message}>
                    <p>$5 = 5 + 1 coins</p>
                    <p>$10 = 11 + 3 coins</p>
                    <p>$20 = 23 + 7 coins</p>
                    <p>$50 = 60 + 20 coins</p>
                    <p>S100= 130 + 40 coins</p>
                </div>
                <div className={styles.bannerBook}>
                    <Link to="/order_online">
                    <Button
                        format={useIsMobile() ? "white" : "default"}
                    >Buy Token</Button>
                    </Link>
                </div>
            </div>
            <div className={styles.bannerForm}>
                <Contact />
            </div>
        </div>
    );
}

export default Banner;