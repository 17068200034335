import React from 'react';
import styles from './Environment.module.scss';
const images = [
    'assets/images/env1.png',
    'assets/images/env2.png',
    'assets/images/env3.png',
    'assets/images/env4.png',
    'assets/images/env5.png',
    'assets/images/env6.png',
    'assets/images/env7.png',
    'assets/images/env8.png',
    'assets/images/env9.png',
];

const Environment = () => {
    return (
        <div className={styles.environment}>
            <h2>Our Product</h2>
            <div className={styles.envImages}>
                {
                    images.map((image, index) => (
                        <div className={styles.envSingleImage} key={index}>
                            <img 
                                src={image}
                                alt={image}
                            />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Environment;