import styles from "./Advantages.module.scss";

const Advantages = () => {

    const whiteBear = "/assets/images/bear-icon-white.png";

    return (
        <>
        <div className={styles.advWrapper}>
            <div className={styles.title}>
                <img 
                src={whiteBear}
                alt="PokinPokin Home Page"
                />
                <h1>OUR<br/>ADVANTAGES</h1>
            </div>
            <div className={styles.points}>
                <div className={styles.point}>
                    <h1>Global Expansion</h1>
                    <p>PokinPokin is committed to expanding our brand influence globally. Our successful entry into the North American market marks a significant milestone. By partnering with the world-renowned real estate management company "Oxford Properties," we opened our first flagship store in North America at Upper Canada Mall. Today, PokinPokin's offline stores are located in major shopping malls across Canada, continually expanding our reach and impact. Our ambition is to establish PokinPokin as one of the most beloved claw machine brands worldwide.</p>
                </div>
                <div className={styles.point}>
                    <h1>Brand Scale and Innovation</h1>
                    <p>PokinPokin's rapid growth is remarkable, far outpacing our competitors. We offer a wide variety of IP products designed to meet the needs of different consumer groups. Positioned as a retailer rather than an agent, we maintain close partnerships with the world's leading manufacturers of genuine IP plush toys. Through these relationships, we ensure top-tier supply chain integration, product diversity, and professionalism, providing customers with the highest quality shopping experience.</p>
                </div>
                <div className={styles.point}>
                    <h1>Continuous Iteration and Customer Engagement</h1>
                    <p>To maintain high-speed, high-quality product iteration, our professional supply chain team selects outstanding IP derivatives from a vast network of suppliers. Our stores undergo a complete product refresh approximately every 30 days, ensuring a constantly evolving shopping experience. This approach keeps our customers engaged and excited, offering new experiences every week and significant changes every month. Through continuous product updates and iterations, we consistently enhance customer satisfaction and brand loyalty.</p>
                </div>
            </div>
        </div>
        </>
    );

};

export default Advantages;