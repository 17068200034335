import styles from "./Message.module.scss";
import axios from 'axios';
import { useState } from 'react';

const Message = () => {

    const apiUrl = 'https://test.tekoai.com/chatbotapi';

    const [formData, setFormData] = useState({
        name: '',
        phone_num: '',
        email: '',
        notes: '',
        bot_id: 241 
    });
    const [message, setMessage] = useState(''); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/addWebBuildContact/addWebBuildContact`, formData);
            setMessage(response.data.message);
            setFormData({ name: '', phone_num: '', email: '', notes: '', bot_id: 241 });
        } catch (error) {
            setMessage('Error submitting the form');
        }
    };

    return (
        <>
        <div className={styles.contactWrapper}>
            <div className={styles.title}>
                <h1>CONTACT US</h1>
            </div>
            <button className={styles.join}>
                <h1>Join Us Now!</h1>
                <p>example@email.com</p>
            </button>
            <div className={styles.formTitle}>
                <p>Feel free to ask, if you have any questions!</p>
            </div>
            <form onSubmit={handleSubmit} className={styles.contactForm}>
                <div className={styles.contactField}>
                    <input type="text" id="name" name="name" placeHolder="Name*" value={formData.name} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <input type="text" id="phone_num" name="phone_num" placeHolder="Phone" value={formData.phone_num} onChange={handleChange}/>
                </div>
                <div className={styles.contactField}>
                    <input type="email" id="email" name="email" placeHolder="Email*" value={formData.email} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <textarea id="notes" name="notes" placeHolder="Your Message*" value={formData.notes} onChange={handleChange} required/>
                </div>
                <button type="submit" className={styles.button}>Send</button>
                {message && <p>{message}</p>}
            </form>
        </div>
        </>
    );

};

export default Message;