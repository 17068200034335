import styles from "./Banner.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL, CRMFRONT_URL } from "../../../constants";
import axios from "axios";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "../../../context/userContext";
import Cookies from "js-cookie";

const Banner = () => {
  const bear = "/assets/images/bear-icon-white.png";
  const coin = "/assets/images/coin.png";
  const { user } = useContext(UserContext);
  const [showSpecialItem, setShowSpecialItem] = useState(false); // 添加状态
  const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [maskedPhone, setMaskedPhone] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  useEffect(() => {
    const fetchCartDetails = async () => {
      if (!user?.userId) {
        console.error("User is not logged in.");
        return;
      }

      try {
        const response = await axios.get(
          `${BACKEND_URL}crmUsers/user_details_transactions/${user.userId}`
        );
        console.log(response.data); // 打印 API 调用结果

        // 如果是新用户（交易记录为空），显示 10 个币 $1.99 的选项
        if (!response.data || response.data.length === 0) {
          setShowSpecialItem(true); // 更新状态，展示 $1.99 选项
        }
      } catch (error) {
        console.error("Error fetching cart details:", error);
      }
    };

    if (user?.userId) {
      fetchCartDetails();
    }
  }, [user]);

  function addItemToCart(itemId, bundleSize, itemPrice, quantity) {
    const existingItem = cart.find((item) => item.id === itemId);
    if (existingItem) {
      // 更新数量
      existingItem.quantity = quantity;
    } else {
      // 新增商品时分别记录币数量和价格
      cart.push({
        id: itemId,
        size: bundleSize, // 包含币的数量，如 10、11、23 等
        price: itemPrice, // 单个包的价格，如 $1.99、$10 等
        quantity: quantity, // 购买的包数
        totalCoins: bundleSize * quantity, // 总币数
      });
    }

    sessionStorage.setItem("cart", JSON.stringify(cart));
  }

  const removeItem = (itemId) => {
    const newCart = cart.filter((item) => item.id !== itemId);
    sessionStorage.setItem("cart", JSON.stringify(newCart));
  };

  const tenItem = cart.find((item) => item.id === "Small");
  const twentyItem = cart.find((item) => item.id === "Normal");
  const fiftyItem = cart.find((item) => item.id === "Large");
  const hundredItem = cart.find((item) => item.id === "Extra Large");
  const specialItem = cart.find((item) => item.id === "Special");
  const [special, setSpecial] = useState(
    specialItem ? specialItem.quantity : 0
  );

  const addToCart = () => {
    // Ensure the "10 Coins" bundle can only be purchased once
    if (showSpecialItem) {
      if (special > 0) {
        addItemToCart("Special", 10, 1.99, special); // Add the special item based on user-selected quantity
      } else if (specialItem) {
        removeItem("Special"); // Remove the special item if quantity is 0
      }
    }

    // Other logic remains the same
    if (ten !== 0) {
      addItemToCart("Small", 11, 10, ten); // Add 11 coins for $10
    } else if (tenItem) {
      removeItem("Small");
    }
    if (twenty !== 0) {
      addItemToCart("Normal", 23, 20, twenty); // Add 23 coins for $20
    } else if (twentyItem) {
      removeItem("Normal");
    }
    if (fifty !== 0) {
      addItemToCart("Large", 60, 50, fifty); // Add 60 coins for $50
    } else if (fiftyItem) {
      removeItem("Large");
    }
    if (hundred !== 0) {
      addItemToCart("Extra Large", 130, 100, hundred); // Add 130 coins for $100
    } else if (hundredItem) {
      removeItem("Extra Large");
    }
  };

  let ten = 0;
  let twenty = 0;
  let fifty = 0;
  let hundred = 0;

  if (tenItem) {
    ten = tenItem.quantity;
  }
  if (twentyItem) {
    twenty = twentyItem.quantity;
  }
  if (fiftyItem) {
    fifty = fiftyItem.quantity;
  }
  if (hundredItem) {
    hundred = hundredItem.quantity;
  }

  useEffect(() => {
    if (isModalOpen) {
      // Fetch masked phone number and send verification code
      axios
        .post(`${BACKEND_URL}crmCACoins/sendVerificationCode`, {
          userId: user.userId,
          botId: 241,
        })
        .then((response) => {
          setMaskedPhone(response.data.maskedPhone);
        })
        .catch((error) => {
          console.error("Error fetching masked phone number:", error);
          alert("Failed to send verification code. Please try again.");
          setIsModalOpen(false); // 关闭模态框
        });
    }
  }, [isModalOpen, user.userId]);

  const handleAddToCartClick = () => {
    const token = Cookies.get("token"); // 检查 token 是否存在

    if (!token) {
      // 如果用户未登录，跳转到登录链接
      const botId = 241;
      window.location.href = `${CRMFRONT_URL}/login?botId=${botId}`;
    } else {
      // 用户已登录，执行添加到购物车的逻辑并跳转到购物车页面
      addToCart();
      navigate("/cart");
    }
  };

  const handleConfirm = () => {
    // Send verification code to backend for verification
    axios
      .post(`${BACKEND_URL}crmCACoins/verifyCode`, {
        userId: user.userId,
        code: verificationCode,
      })
      .then((response) => {
        if (response.data.success) {
          // Verification successful
          setIsModalOpen(false); // Close the modal
          navigate("/cart"); // Navigate to the cart page
        } else {
          // Verification failed
          alert("Invalid verification code. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying code:", error);
        alert("Failed to verify code. Please try again.");
      });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <>
      <div className={styles.bannerWrapper}>
        <div className={styles.title}>
          <img src={bear} alt="PokinPokin Home Page" />
          <h1>
            ORDER
            <br />
            ONLINE
          </h1>
        </div>
        <div className={styles.subtitle}>
          <h2>COIN PURCHASE PACKAGE</h2>
          <p>
            All price are <span>HST INCLUDED</span>
          </p>
        </div>
        {showSpecialItem && (
          <div className={styles.option}>
            <button className={styles.button}>
              <div className={styles.buttonText}>
                <div className={styles.buttonLeft}>
                  <h1>10</h1>
                  <img src={coin} alt="coin" />
                </div>
                <div className={styles.buttonRight}>
                  <p>$1.99</p>
                </div>
              </div>
            </button>
            <div className={styles.increment}>
              <button
                onClick={() => {
                  if (special > 0) {
                    setSpecial(special - 1);
                  }
                }}
                disabled={special === 0}
              >
                -
              </button>
              <p>{special}</p>
              <button
                onClick={() => {
                  if (special < 1) {
                    setSpecial(special + 1);
                  }
                }}
                disabled={special === 1}
              >
                +
              </button>
            </div>
          </div>
        )}

        <div className={styles.packages}>
          <div className={styles.option}>
            <button className={styles.button}>
              <div className={styles.buttonText}>
                <div className={styles.buttonLeft}>
                  <h1>11</h1>
                  <img src={coin} alt="coin" />
                </div>
                <div className={styles.buttonRight}>
                  <p>$10</p>
                </div>
              </div>
            </button>
            <div className={styles.increment}>
              <button
                onClick={() => {
                  if (ten > 0) {
                    ten -= 1;
                    document.getElementById("ten").textContent = ten;
                  }
                }}
              >
                -
              </button>
              <p id="ten">{ten}</p>
              <button
                onClick={() => {
                  ten += 1;
                  document.getElementById("ten").textContent = ten;
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className={styles.option}>
            <button className={styles.button}>
              <div className={styles.buttonText}>
                <div className={styles.buttonLeft}>
                  <h1>23</h1>
                  <img src={coin} alt="coin" />
                </div>
                <div className={styles.buttonRight}>
                  <p>$20</p>
                </div>
              </div>
            </button>
            <div className={styles.increment}>
              <button
                onClick={() => {
                  if (twenty > 0) {
                    twenty -= 1;
                    document.getElementById("twenty").textContent = twenty;
                  }
                }}
              >
                -
              </button>
              <p id="twenty">{twenty}</p>
              <button
                onClick={() => {
                  twenty += 1;
                  document.getElementById("twenty").textContent = twenty;
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className={styles.option}>
            <button className={styles.button}>
              <div className={styles.buttonText}>
                <div className={styles.buttonLeft}>
                  <h1>60</h1>
                  <img src={coin} alt="coin" />
                </div>
                <div className={styles.buttonRight}>
                  <p>$50</p>
                </div>
              </div>
            </button>
            <div className={styles.increment}>
              <button
                onClick={() => {
                  if (fifty > 0) {
                    fifty -= 1;
                    document.getElementById("fifty").textContent = fifty;
                  }
                }}
              >
                -
              </button>
              <p id="fifty">{fifty}</p>
              <button
                onClick={() => {
                  fifty += 1;
                  document.getElementById("fifty").textContent = fifty;
                }}
              >
                +
              </button>
            </div>
          </div>
          <div className={styles.option}>
            <button className={styles.button}>
              <div className={styles.buttonText}>
                <div className={styles.buttonLeft}>
                  <h1>130</h1>
                  <img src={coin} alt="coin" />
                </div>
                <div className={styles.buttonRight}>
                  <p>$100</p>
                </div>
              </div>
            </button>
            <div className={styles.increment}>
              <button
                onClick={() => {
                  if (hundred > 0) {
                    hundred -= 1;
                    document.getElementById("hundred").textContent = hundred;
                  }
                }}
              >
                -
              </button>
              <p id="hundred">{hundred}</p>
              <button
                onClick={() => {
                  hundred += 1;
                  document.getElementById("hundred").textContent = hundred;
                }}
              >
                +
              </button>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div className={styles.modalOverlay} onClick={handleCloseModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <button className={styles.closeButton} onClick={handleCloseModal}>
                X
              </button>
              <p>
                Please enter the verification code received at {maskedPhone}
              </p>
              <input
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className={styles.modalInput}
              />
              <button className={styles.confirmButton} onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
        )}
        <div className={styles.note}>
          <p>The machine only accepts banknotes, coins are not accepted.</p>
          <p>(We can help you to convert coins into banknotes)</p>
        </div>
        <button className={styles.addCart} onClick={handleAddToCartClick}>
          Add To Cart
        </button>
      </div>
    </>
  );
};

export default Banner;
