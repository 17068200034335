import styles from "./Banner.module.scss";
import { useNavigate } from 'react-router-dom';

const Banner = () => {

    const bear = "/assets/images/bear-icon.png";
    const cake = "/assets/images/birthday_cake.png";

    const navigate = useNavigate();
    const handleLocationClick = () => {
        navigate(`/landing`);
    };

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Special Events Page"/>
                <h1>SPECIAL<br/>EVENTS</h1>
            </div>
            <div className={styles.content}>
                <img src={cake} alt="Birthday Cake"/>
                <div className={styles.words}>
                    <h2>Birthday Party</h2>
                    <p>Want to make a reservation? Choose the store you'd like to<br/>book and click 'Call Now' to give us a call and schedule your<br/>birthday party!</p>
                    <button className={styles.location} onClick={() => handleLocationClick()}>
                        Upper Canada Mall Store
                    </button>
                    <button className={styles.location} onClick={() => handleLocationClick()}>
                        Scarborough Town Centre Store
                    </button>
                    <button className={styles.location} onClick={() => handleLocationClick()}>
                        Metropolis at Metrotown Store
                    </button>
                    <button className={styles.contact} onClick={() => handleLocationClick()}>
                        Contact Us
                    </button>
                </div>
            </div>
        </div>
        </>
    );

};

export default Banner;