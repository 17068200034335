import Button from './Button';
import styles from './Header.module.scss';
import { Link } from "react-router-dom";

const Header = () => {
    const locationSvg = "/assets/images/location.svg";
    const logoSvg = "/assets/images/logo.svg";

    return (
        <div className={styles.header}>
            <div className={styles.headerFlex}>
                <img src={locationSvg} alt="Location" />
                <div className={styles.location}>
                    <p>17600 Yonge St, New Market, </p>
                    <p>ON L3Y 4Z1</p>
                </div>
            </div>
            <div className={styles.headerFlex}>
                <Link to="/">   
                <img src={logoSvg} alt="Logo" />
                </Link>
            </div>
            <div className={styles.headerFlex}>
                <div>Book Now!</div>
                <a href="tel:+3655500060">
                    <Button>
                        (905) 869-3098
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default Header;