// contact form for book appointment
import Button from './Button';
import styles from './Contact.module.scss';
import axios from 'axios';
import { useState } from 'react';
import { BACKEND_URL } from '../../../constants';

const Contact = () => {
    const apiUrl = BACKEND_URL;

    const [formData, setFormData] = useState({
        location: '',
        name: '',
        phone_num: '',
        email: '',
        bot_id: 241 
    });

    const [message, setMessage] = useState(''); 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const response = await axios.post(`${apiUrl}addWebBuildContact/addWebBuildContact`, formData);
            setMessage(response.data.message);
            setFormData({ location: '', name: '', phone_num: '', email: '', bot_id: 241 });
        } catch (error) {
            setMessage('Error submitting the form');
        }
    };

    return (
        <div className={styles.contact}>
            <h2>Birthday Party Reservation</h2>
            <p>Welcome to our store, and we look forward to sharing more joyful moments with you through our plush toys!</p>
            <p>Please leave your phone number or email, and we will send you the link to purchase tokens, along with our latest promotional information and updates on our new store openings.</p>
            <form onSubmit={handleSubmit}>
            <div className={styles.contactForm}>
                <div className={styles.contactField}>
                    <h1>Store</h1>
                        <select id="location" name="location" value={formData.location} onChange={handleChange} required>
                            <option value="">Select your Store</option>
                            <option value="uppercanadamall">Upper Canada Mall Store</option>
                            <option value="scarborough">Scarborough Town Centre Store</option>
                            <option value="metrotown">Metropolis at Metrotown Store</option>
                        </select>
                </div>
                <div className={styles.contactField}>
                    <h1>Name</h1>
                    <input type="text" id="name" name="name" placeHolder="John Doe" value={formData.name} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <h1>Phone</h1>
                    <input type="text" id="phone_num" name="phone_num" placeHolder="123-456-7890" value={formData.phone_num} onChange={handleChange} required/>
                </div>
                <div className={styles.contactField}>
                    <h1>Email</h1>
                    <input type="email" id="email" name="email" placeHolder="johndoe@mail.com" value={formData.email} onChange={handleChange} required/>
                </div>
                </div>
            <Button type="submit">Subscribe</Button>
            {message && <p>{message}</p>}
            </form>
            
        </div>
    );
}

export default Contact;