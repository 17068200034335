import { Scroll } from "../../components";
import {
    Banner,
} from "./components";

const UserDetails = () => {
    return (
        <>
        <Banner />
        <Scroll />
        </>
    );
};

export default UserDetails;
