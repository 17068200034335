import { Scroll } from "../../components"

import {
    Banner,
    About,
    Brand,
    Advantages,
    Products,
    Location,
    Contact,
} from "./components"

const Home = () => {
    return (
        <>
        <Banner />
        <Scroll />
        <About />
        <Scroll />
        <Brand />
        <Advantages />
        <Products />
        <Scroll />
        <Location />
        <Scroll />
        <Contact />
        </>
    );
};

export default Home;