import styles from './Button.module.scss';

const Button = ({ children, format = 'default', onClick = () => { } }) => {
    return (
        <button className={
            format === 'default' ? styles.button : styles.buttonWhite
        }
            onClick={onClick}>
            {children}
        </button>
    );
}

export default Button;