import styles from "./Location.module.scss";

const Location = () => {

    const bear = "/assets/images/bear-icon.png"
    const photo = "/assets/images/location.png";
    const upcoming = "/assets/images/upcoming.png";
    const map = "/assets/images/location-icon.png";
    const phone = "/assets/images/phone-icon.png";

    return (
        <>
        <div className={styles.locationWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Home Page"/>
                <h1>OUR<br/>LOCATION</h1>
            </div>
            <div className={styles.locations}>
                <div className={styles.location}>
                    <img src={photo} alt="PokinPokin Location"/>
                    <h1>Upper Canada Mall Store</h1>
                    <div className={styles.address}>
                        <img src={map} alt="PokinPokin Location"/>
                        <p>17600 Yonge St, Newmarket, ON L3Y 4Z1</p>
                    </div>
                    <div className={styles.phone}>
                        <img src={phone} alt="PokinPokin Phone"/>
                        <p>+1 (905) 869-3098</p>
                    </div>
                </div>
                <div className={styles.location}>
                    <img src={upcoming} alt="PokinPokin Location"/>
                    <h1>Scarborough Town Centre Store</h1>
                    <div className={styles.address}>
                        <img src={map} alt="PokinPokin Location"/>
                        <p>300 Borough Dr, Scarborough, ON M1P 4P5</p>
                    </div>
                    <div className={styles.phone}>
                        <img src={phone} alt="PokinPokin Phone"/>
                        <p>+1 (905) 869-3098</p>
                    </div>
                </div>
                <div className={styles.location}>
                    <img src={upcoming} alt="PokinPokin Location"/>
                    <h1>Metropolis at Metrotown Store</h1>
                    <div className={styles.address}>
                        <img src={map} alt="PokinPokin Location"/>
                        <p>4700 Kingsway, Burnaby, BC V5H 4N2</p>
                    </div>
                    <div className={styles.phone}>
                        <img src={phone} alt="PokinPokin Phone"/>
                        <p>N/A</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );

};

export default Location;