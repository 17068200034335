import styles from "./Products.module.scss";
import { useNavigate } from 'react-router-dom';

const Products = () => {

    const bear = "/assets/images/bear-icon.png";
    const mini = "/assets/images/mini-plush.png";
    const standard = "/assets/images/standard-plush.png";
    const giant = "/assets/images/giant-plush.png";
    const navigate = useNavigate();

    return (
        <>
        <div className={styles.productWrapper}>
            <div className={styles.title}>
                <img
                src={bear}
                alt="PokinPokin Home Page"
                />
                <h1>OUR<br/>PRODUCT</h1>
            </div>
            <div className={styles.buttons}>
                <button onClick={() => navigate('/gallery?category=mini')} className={styles.point}>
                    <img src={mini} alt="Mini Plush"/>
                    <h1>Mini Plush</h1>
                </button>
                <button onClick={() => navigate('/gallery?category=standard')} className={styles.point}>
                    <img src={standard} alt="Standard Plush"/>
                    <h1>Standard Plush</h1>
                </button>
                <button onClick={() => navigate('/gallery?category=giant')} className={styles.point}>
                    <img src={giant} alt="Giant Plush"/>
                    <h1>Giant Plush</h1>
                </button>
            </div>
        </div>
        </>
    );

};

export default Products;