import styles from "./Banner.module.scss"
import { Link } from "react-router-dom";
import { useIsMobile } from "../../../hooks/useIsMobile.js";

const Banner = () => {
    const background = "./assets/images/home-background.png";
    const logoWhite = "./assets/images/logo-white.png";
    const login = "./assets/images/login.png";
    const shop = "./assets/images/shopping.png";
    const videoId = "Bv9_rrW3p0M";
    const youtubevideo = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&loop=1&playlist=${videoId}&controls=0&showinfo=0&rel=0`;

    const isMobile = useIsMobile();

    return (
        <>
            <div className={styles.bannerWrapper}>
                <iframe
                    src={youtubevideo}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <img
                src={logoWhite}
                alt="PokinPokin"
                className={styles.logo}
                />
                <div className={styles.orderButton}>
                    <Link to="/order_online">
                    <button>
                        Order Online
                    </button>
                    </Link>
                    <Link to="/special_events">
                    <button>
                        Birthday Party
                    </button>
                    </Link>
                </div>
                {isMobile ? (
                    <>
                    <Link to="/login">
                    <div className={styles.mobileLoginButton}>
                        <img
                        src={login}
                        alt="Login"
                        />
                    </div>
                    </Link>
                    <Link to="/cart">
                    <div className={styles.mobileCartButton}>
                        <img
                        src={shop}
                        alt="Shopping Cart"
                        />
                    </div>
                    </Link>
                    </>
                ) : null}
            </div>
        </>
    );
};

export default Banner;