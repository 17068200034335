import { createContext, useState, useEffect } from "react";
import Cookies from "js-cookie"; // 引入 js-cookie

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ userId: null, userType: null });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userId = Cookies.get("user_id");
    const userType = Cookies.get("user_type");

    if (userId && userType) {
      setUser({ userId, userType });
    }
    setIsLoading(false); // 用户数据加载完成
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {!isLoading && children} {/* 当加载完成时渲染子组件 */}
    </UserContext.Provider>
  );
};
