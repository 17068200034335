import {
    Banner,
} from "./components";

import { Scroll } from "../../components";

const Checkout = () => {

    return (
        <>
        <Banner />
        <Scroll />
        </>
    );

};

export default Checkout;