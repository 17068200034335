import styles from './Reviews.module.scss';
const reviews = [
    {
        id: 1,
        name: 'Anna Liu',
        review: '"This store was really cute and adorable 😍 The employees were very nice and friendly as well!"',
        rating: 5,
        path: "https://maps.app.goo.gl/BLhXj27ZtpNiua276",
    },
    {
        id: 2,
        name: 'Melissa Pennell',
        review: '"Best place ever !!! Nicole and Ning are spectacular! Super helpful and they have the most amazing prizes !!! Highly recommend !!"',
        rating: 5,
        path: "https://maps.app.goo.gl/x4jnPynzKwLqfzWj7",
    },
    {
        id: 3,
        name: 'Max Guevara',
        review: '"Nicole was a great help! The place has a great selection of prizes, there’s something for everyone. Nicole was very helpful and customer service oriented."',
        rating: 5,
        path: "https://maps.app.goo.gl/JaAV3X8yvbp8pG1H9",
    },
    {
        id: 4,
        name: 'Kim Drimmel',
        review: '"Visited with my 4 year old daughter and she loved it. You actually win prizes unlike other claw machines or arcades. My daughter was so excited she won so many prizes and felt more worth the money then other arcades. It\'s super fun and honestly a little addicting. I will definately come back!"',
        rating: 5,
        path: "https://maps.app.goo.gl/ZWN4Duquzb8Sc6SD7",
    },
    {
        id: 5,
        name: 'Bryan Valladares',
        review: '"visited here earlier today and Nicole was the absolute best and most helpful! loved her energy and attitude. it was my first time visiting and I now hope Nicole’s here every time I come back. after a few tries we were able to leave with a a cute bunny and dinosaur, very happy :)"',
        rating: 5,
        path: "https://maps.app.goo.gl/j6iXZubyEZBcSUAd8",
    },
    {
        id: 6,
        name: 'Jennifer Y-S',
        review: '"This place is so much fun!!! The prizes are so cute and not too hard to win. The staff are really nice. Highly recommend checking this place out!! My daughter and her friend won two cute stuffies each !!"',
        rating: 5,
        path: "https://maps.app.goo.gl/YzRBReFwLyjyoGiVA",
    }
]

const Reviews = () => {
    const starSvg = 'assets/images/star.svg';
    return (
        <div className={styles.reviewsWrapper}>
            <h2>The joy of our customers are our inspiration</h2>
            <p>As a high-quality store with a 4.8-star rating on Google Business, we are always committed to bringing you the best plush toy experience.</p>
            <div className={styles.reviews}>
                {
                    reviews.map((review) => (
                        <a href={review.path} className={styles.singleReview}>
                        <div key={review.id}>
                            <h2>{review.name}</h2>
                            {
                                Array(review.rating).fill().map((_, index) => (
                                    <img key={index} src={starSvg} alt="star" />
                                ))
                            }
                            <p>{review.review}</p>
                        </div>
                        </a>
                    ))
                }
            </div>
        </div>
    );
}

export default Reviews;