import styles from "./Banner.module.scss";
import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';

const Banner = () => {

    const bear = "/assets/images/bear-icon.png"
    const image = "/assets/images/location.png";
    const miniImages = ["/assets/images/mini1.jpg",
        "/assets/images/mini2.jpg",
        "/assets/images/mini3.jpg",
        "/assets/images/mini4.jpg",
        "/assets/images/mini5.jpg",
        "/assets/images/mini6.jpg",
        "/assets/images/mini7.jpg",
        "/assets/images/mini8.jpg",
        "/assets/images/mini9.jpg",
        "/assets/images/mini10.jpg",
        "/assets/images/mini11.jpg",
        "/assets/images/mini12.jpg",
        "/assets/images/mini13.jpg",
        "/assets/images/mini14.jpg",
        "/assets/images/mini15.jpg",
        "/assets/images/mini16.jpg",
        "/assets/images/mini17.jpg"]
    const standardImages = ["/assets/images/standard1.jpg",
        "/assets/images/standard2.jpg",
        "/assets/images/standard3.jpg",
        "/assets/images/standard4.jpg",
        "/assets/images/standard5.jpg",
        "/assets/images/standard6.jpg",
        "/assets/images/standard7.jpg",
        "/assets/images/standard8.jpg",
        "/assets/images/standard9.jpg",
        "/assets/images/standard10.jpg",
        "/assets/images/standard11.jpg"]
    const giantImages = ["/assets/images/giant1.jpg",
        "/assets/images/giant2.jpg",
        "/assets/images/giant3.jpg",
        "/assets/images/giant4.jpg"]
    const left = "/assets/images/dark-left.png";
    const right = "/assets/images/dark-right.png";


    const [currentImages, setCurrentImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeCategory, setActiveCategory] = useState("mini");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const category = searchParams.get('category') || "mini"; // 默认类别为 mini
        handleButtonClick(category);
    }, [searchParams]);

    const handleButtonClick = (type) => {
        let images = [];
        if (type === "mini") {
            images = miniImages;
        } else if (type === "standard") {
            images = standardImages;
        } else if (type === "giant") {
            images = giantImages;
        }
        setCurrentImages(images);
        setCurrentIndex(0);
        setActiveCategory(type);
    };

    const nextImages = () => {
        if (currentIndex + 6 < currentImages.length) {
            setCurrentIndex(currentIndex + 6);
        }
    };

    const prevImages = () => {
        if (currentIndex - 6 >= 0) {
            setCurrentIndex(currentIndex - 6);
        }
    };

    const displayedImages = currentImages.slice(currentIndex, currentIndex + 6);

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Special Events Page"/>
                <h1>OUR<br/>PRODUCT</h1>
            </div>
            <div className={styles.buttons}>
                <button 
                    className={`${styles.button} ${activeCategory === "mini" ? styles.active : ""}`} 
                    onClick={() => handleButtonClick("mini")}>
                    <p>Mini Plush</p>
                </button>
                <button 
                    className={`${styles.button} ${activeCategory === "standard" ? styles.active : ""}`}
                    onClick={() => handleButtonClick("standard")}>
                    <p>Standard Plush</p>
                </button>
                <button 
                    className={`${styles.button} ${activeCategory === "giant" ? styles.active : ""}`}
                    onClick={() => handleButtonClick("giant")}>
                    <p>Giant Plush</p>
                </button>
            </div>
                <div className={styles.gallery}>
                {displayedImages.map((img, index) => (
                    <img key={index} src={img} alt={`PokinPokin Gallery ${currentIndex + index + 1}`} />
                ))}
            </div>
            <div className={styles.mover}>
                <img 
                    src={left}
                    alt="Left Arrow" 
                    className={styles.left} 
                    onClick={prevImages} 
                    style={{ cursor: currentIndex > 0 ? 'pointer' : 'not-allowed', opacity: currentIndex > 0 ? 1 : 0.5 }} 
                />
                <img 
                    src={right}
                    alt="Right Arrow" 
                    className={styles.right} 
                    onClick={nextImages} 
                    style={{ cursor: currentIndex + 6 < currentImages.length ? 'pointer' : 'not-allowed', opacity: currentIndex + 6 < currentImages.length ? 1 : 0.5 }} 
                />
            </div>
        </div>
        </>
    );

};

export default Banner;