import styles from './Map.module.scss';
import Button from "./Button";
const locationSvg = "/assets/images/map-location.png";
const shopSvg = "/assets/images/map-phone.png";
const Map = () => {
    return (
        <div className={styles.mapWrapper}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4054.9891940489206!2d-79.49029317825664!3d44.05629958841349!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ad1015d43a8c5%3A0x92705062503a6225!2sPokinPokin!5e0!3m2!1sen!2sca!4v1723247361871!5m2!1sen!2sca"
                width="100%" height="480" style={{ border: 0 }}
                allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                title='map'
                draggable="false"
            >
            </iframe>
            <div className={styles.mapText}>
                <div className={styles.mapLocation}>
                    <div>
                        <img src={locationSvg} alt="Location" />
                        <h2>PokinPokin (Upper Canada Mall)</h2>
                        <p>17600 Yonge St, Newmarket,<br/>ON L3Y 4Z1</p>
                    </div>
                    <div>
                        <img src={shopSvg} alt="Shop" />
                        <h2>(905) 869-3098</h2>
                        <a href="tel:+9058693098">
                        <Button format="white">Call Us!</Button>
                        </a>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.mapLocation}>
                    <div>
                        <img src={locationSvg} alt="Location" />
                        <h2>PokinPokin (Scarborough Town Centre)</h2>
                        <p>300 Borough Dr, Scarborough,<br/>ON M1P 4P5</p>
                    </div>
                    <div>
                        <img src={shopSvg} alt="Shop" />
                        <h2>(905) 869-3098</h2>
                        <a href="tel:+9058693098">
                        <Button format="white">Call Us!</Button>
                        </a>
                    </div>
                </div>
                <div className={styles.divider}></div>
                <div className={styles.mapLocation}>
                    <div>
                        <img src={locationSvg} alt="Location" />
                        <h2>PokinPokin (Metropolis at Metrotown)</h2>
                        <p>4700 Kingsway, Burnaby,<br/>BC V5H 4N2</p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Map;