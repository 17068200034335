import styles from './About.module.scss';

const About = () => {

    return (
        <div className={styles.about}>
            <div className={styles.desc}>
            <h1>About PokinPokin</h1>
            <p>At our place, grabbing a plush toy is easier than ever. Our machines are designed to give you a real chance at winning, so you'll be walking away with a prize in no time. We pride ourselves on offering high-quality plushies that are soft, durable, and absolutely adorable. Plus, we keep things fresh by updating our selection every week—there's always something new and exciting to discover. Each plush toy has its own unique personality, making them even more special to collect. And if you ever need a hand, our friendly and well-trained staff is here to help adjust the toys so you can have the best shot at winning. Come by and enjoy a fun, rewarding experience every time you visit!</p>
            </div>
        </div>
    );

};

export default About;