import styles from "./Banner.module.scss";
import { useState } from "react";
import { Link } from "react-router-dom";

const Banner = () => {
  const bear = "/assets/images/bear-icon-white.png";
  const coin = "/assets/images/coin.png";
  const trash = "/assets/images/delete-icon.png";

  const [cart, setCart] = useState(
    JSON.parse(sessionStorage.getItem("cart")) || []
  );

  const removeItem = (itemId) => {
    const newCart = cart.filter((item) => item.id !== itemId);
    setCart(newCart);
    sessionStorage.setItem("cart", JSON.stringify(newCart));
  };

  const updateQuantity = (itemId, change) => {
    const newCart = cart.map((item) => {
      if (item.id === itemId) {
        const newQuantity = item.quantity + change;
        return { ...item, quantity: newQuantity > 1 ? newQuantity : 1 };
      }
      return item;
    });

    setCart(newCart);
    sessionStorage.setItem("cart", JSON.stringify(newCart));
  };

  return (
    <>
      <div className={styles.cartWrapper}>
        <div className={styles.title}>
          <img src={bear} alt="PokinPokin Home Page" />
          <h1>
            CART
            <br />
            DETAIL
          </h1>
        </div>
        <div className={styles.subtitle}>
          <h2>PLEASE MAKE SURE YOU GOT EVERYTHING YOU NEED!</h2>
          <p>
            All price are <span>HST INCLUDED</span>
          </p>
        </div>
        <div className={styles.itemList}>
          {cart.length === 0 ? (
            <p className={styles.noItems}>No items in cart</p>
          ) : (
            cart.map((item) => (
              <div className={styles.item} key={item.id}>
                <div className={styles.buttonText}>
                  <div className={styles.buttonName}>
                    <img src={coin} alt="coin" />
                    <h1>{item.size * item.quantity}</h1> {/* 显示币的数量 */}
                    <h1>coins</h1>
                  </div>
                  <p className={styles.price}>
                    ${(item.price * item.quantity).toFixed(2)}{" "}
                    {/* 使用 toFixed(2) 来确保显示精确的小数 */}
                  </p>

                  <div className={styles.increment}>
                    <p
                      className={styles.minus}
                      onClick={() => updateQuantity(item.id, -1)}
                    >
                      -
                    </p>
                    <p>{item.quantity}</p> {/* 显示购买的包数 */}
                    <p
                      className={styles.plus}
                      onClick={() => updateQuantity(item.id, 1)}
                    >
                      +
                    </p>
                  </div>
                  <div className={styles.trash}>
                    <img
                      src={trash}
                      alt="Trashcan"
                      onClick={() => removeItem(item.id)}
                    />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className={styles.buttons}>
          <Link to="/order_online" className={styles.back}>
            Back
          </Link>
          <Link to="/checkout" className={styles.checkout}>
            Pay Now
          </Link>
        </div>
      </div>
    </>
  );
};

export default Banner;
