import { useEffect, useState, useContext } from "react";
import axios from "axios";
import styles from "./Banner.module.scss";
import { BACKEND_URL } from "../../../constants";
import { UserContext } from "../../../context/userContext";
import Cookies from "js-cookie";

const Banner = () => {
  const profileImage = "./assets/images/user-default-avatar.svg"; // 用户头像
  const coinIcon = "./assets/images/coin-icon.svg"; // 硬币图标
  const rewardIcon = "./assets/images/award-icon.svg"; // 奖励图标
  const checkIcon = "./assets/images/check-icon.svg"; // 验证图标
  const bear = "./assets/images/bear-icon.png"; // 图片
  const activityHistoryIcon = "./assets/images/activity-history.svg"; // 活动历史图标

  // 使用 useContext 获取上下文数据
  const { user, setUser } = useContext(UserContext);

  const [userData, setUserData] = useState(null); // 存储用户信息

  const [userBizInfo, setUserBizInfo] = useState(null); // 存储业务信息
  const [transactions, setTransactions] = useState([]); // 存储交易记录

  // 获取用户数据
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // 使用上下文中的 user.userId 代替硬编码的 userId
        const userId = user?.userId;
        if (!userId) {
          console.error("User is not logged in.");
          return;
        }

        const userResponse = await axios.get(
          `${BACKEND_URL}crmUsers/user_details_info/${userId}`
        );
        const bizInfoResponse = await axios.get(
          `${BACKEND_URL}crmUsers/user_details_biz_info/${userId}`
        );
        const transactionsResponse = await axios.get(
          `${BACKEND_URL}crmUsers/user_details_transactions/${userId}`
        );

        setUserData(userResponse.data);
        setUserBizInfo(bizInfoResponse.data);
        setTransactions(transactionsResponse.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user?.userId]); // 监听 user.userId 的变化

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.titleSection}>
        <img src={bear} alt="PokinPokin Special Events Page" />
        <h1>
          USER
          <br />
          DETAILS
        </h1>
      </div>

      <div className={styles.leftRightWrapper}>
        {/* 左边部分 - 用户信息 */}
        <div className={styles.leftSection}>
          <div className={styles.profileContainer}>
            <img
              src={profileImage}
              alt="User Profile"
              className={styles.profileImage}
            />
            <h1 className={styles.userName}>
              {userData?.username || "N/A"} {/* 如果没有数据则显示 N/A */}
            </h1>
            <span className={styles.userId}>#{userData?.user_id || "N/A"}</span>
          </div>

          <div className={styles.userInfo}>
            {userData?.email && (
              <div className={styles.infoRow}>
                <span className={styles.label}>Email</span>
                <span className={styles.value}>{userData.email}</span>
                <img
                  src={checkIcon}
                  alt="Verified"
                  className={styles.verifiedIcon}
                />
              </div>
            )}
            {userData?.phone && (
              <div className={styles.infoRow}>
                <span className={styles.label}>Phone</span>
                <span className={styles.value}>{userData.phone}</span>
                <img
                  src={checkIcon}
                  alt="Verified"
                  className={styles.verifiedIcon}
                />
              </div>
            )}
          </div>

          <div className={styles.pointsContainer}>
            <div className={styles.pointsBox}>
              <img src={rewardIcon} alt="Reward Icon" className={styles.icon} />
              <span>{userBizInfo?.points || 0} pt</span>
            </div>
            <div className={styles.coinsBox}>
              <img src={coinIcon} alt="Coin Icon" className={styles.icon} />
              <span>{userBizInfo?.coins || 0}</span>
            </div>
          </div>
          <div className={styles.logoutButtonContainer}>
            <button
              type="button"
              className={styles.logoutButton}
              onClick={() => {
                Cookies.remove("user_id"); // 删除 cookie 中的 user_id
                Cookies.remove("user_type"); // 删除 cookie 中的 user_type
                Cookies.remove("token");
                setUser({ userId: null, userType: null }); // 清空 UserContext 中的用户信息
                window.location.reload(); // 刷新页面，重定向到登录页或初始状态
              }}
            >
              Log Out
            </button>
          </div>
        </div>

        {/* 右边部分 - 活动历史 */}
        <div className={styles.rightSection}>
          <div className={styles.activityHeader}>
            <img
              src={activityHistoryIcon}
              alt="Activity History"
              className={styles.activityHistoryIcon}
            />
            <h2>Activity Feed ({transactions.length})</h2>
          </div>
          <div className={styles.activityFeed}>
            {transactions.length === 0 ? (
              <div>No recent activity</div>
            ) : (
              transactions.map((transaction, index) => (
                <div key={index} className={styles.activityDay}>
                  <span className={styles.activityDate}>
                    {new Date(transaction.timestamp).toLocaleDateString()}
                  </span>
                  <div className={styles.activityRow}>
                    <img
                      src={checkIcon}
                      alt="Success"
                      className={styles.icon}
                    />
                    <span>
                      {transaction.transaction_type} / {transaction.amount}
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
