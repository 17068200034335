import styles from "./Banner.module.scss";

const Banner = () => {

    const bear = "/assets/images/bear-icon.png";

    return (
        <>
        <div className={styles.bannerWrapper}>
            <div className={styles.title}>
                <img src={bear} alt="PokinPokin Special Events Page"/>
                <h1>NEWS AND<br/>UPDATES</h1>
            </div>
            <div className={styles.news}>
                <button className={styles.button}>
                    <div className={styles.filler}/>
                    <h2>STC Store</h2>
                    <p>Our Scarborough Town Centre Store will open soon..</p>
                </button>
                <button className={styles.button}>
                    <div className={styles.filler}/>
                    <h2>STC Store</h2>
                    <p>Our Scarborough Town Centre Store will open soon..</p>
                </button>
                <button className={styles.button}>
                    <div className={styles.filler}/>
                    <h2>STC Store</h2>
                    <p>Our Scarborough Town Centre Store will open soon..</p>
                </button>
            </div>
        </div>
        </>
    );

};

export default Banner;