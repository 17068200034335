import styles from "./Scroll.module.scss"

const icon1 = "./assets/images/scrollbar-icon1.png";
const icon2 = "./assets/images/scrollbar-icon2.png";
const icon3 = "./assets/images/scrollbar-icon3.png";

const Scroll = () => {
    return (
        <>
        <div className={styles.container}>
            <div className={styles.scroll}>
                <div className={styles.item}>
                    <img
                        src={icon1}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon1}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>
                    <img
                        src={icon2}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon2}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>
                    <img
                        src={icon3}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon3}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>
                    <img
                        src={icon1}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon1}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>
                    <img
                        src={icon2}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon2}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>
                    <img
                        src={icon3}
                        alt="PokinPokin"
                    />
                </div>
                <div className={styles.item}>POKINPOKIN</div>
                <div className={styles.item}>
                    <img
                        src={icon3}
                        alt="PokinPokin"
                    />
                </div>
            </div>
        </div>
        </>
    );
};

export default Scroll;