import { useLocation, Link } from "react-router-dom";
import styles from "./Header.module.scss";
import { useState } from 'react';
import Menu from "./Menu";
import { useIsMobile } from '../hooks/useIsMobile';

const Header = () => {
    const location = useLocation();
    const isLandingPage = location.pathname === "/landing";
    const isLoginPage = location.pathname === "/login";
    const isRegisterPage = location.pathname === "/register";

    const closedMenu = "/assets/images/closed-menu.png";
    const openMenu = "/assets/images/open-menu.png";
    const menuOff = "/assets/images/menu-off.png";
    const menuOn = "/assets/images/menu-on.png";
    const instagram = "/assets/images/instagram.png";
    const openInstagram = "/assets/images/open-insta.png";
    const tiktok = "/assets/images/tiktok.png";
    const openTiktok = "/assets/images/open-tiktok.png";
    const login = "./assets/images/login.png";
    const loginHover = "./assets/images/login-hover.png";
    const shop = "./assets/images/shopping.png";
    const shopHover = "./assets/images/shopping-hover.png";

    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = () => {
        setShowMenu(!showMenu);
        if (!showMenu) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }

        const noHover = document.getElementById("noHover");
        const yesHover = document.getElementById("yesHover");

        if(noHover.src.includes(closedMenu)){
            noHover.src = menuOn;
        } else {
            noHover.src = closedMenu;
        }

        if(yesHover.src.includes(openMenu)){
            yesHover.src = menuOff;
        } else {
            yesHover.src = openMenu;
        }
    }

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const handleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
        if (!showMobileMenu) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }

        const mobile = document.getElementById("mobile");
        const mobileWrapper = document.getElementById("mobileWrapper");

        if(mobile.src.includes(closedMenu)){
            mobile.src = menuOn;
            mobileWrapper.style.borderWidth = "0px 2px 2px 0px";
        } else {
            mobile.src = closedMenu;
            mobileWrapper.style.borderWidth = "0px 0px 0px 0px";
        }
    }

    const isMobile = useIsMobile();

    if(!isLandingPage && !isLoginPage && !isRegisterPage){
        return (
            <>
            {!isMobile ? (
                // Desktop Version
                <div className={styles.headerWrapper}>
                    <div className={styles.bar}>
                        <div className={styles.icon} onClick={handleMenu}>
                            <img
                            id="noHover"
                            src={closedMenu}
                            alt="menu"
                            className={styles.image}
                            />
                            <img
                            id="yesHover"
                            src={openMenu}
                            alt="menu"
                            className={styles.hoverImage}
                            />
                        </div>
                        <div className={styles.title}>
                            <a href="/">
                                POKINPOKIN
                            </a>
                        </div>
                        <div className={styles.icon} onClick={() => window.open("https://www.tiktok.com/@pokinpokinucm")}>
                            <img
                            src={tiktok}
                            alt="tiktok"
                            className={styles.image}
                            />
                            <img
                            src={openTiktok}
                            alt="tiktok"
                            className={styles.hoverImage}
                            />
                        </div>
                        <div className={styles.icon} onClick={() => window.open("https://www.instagram.com/pokinpokin_ucm/")}>
                            <img
                            src={instagram}
                            alt="instagram"
                            className={styles.image}
                            />
                            <img
                            src={openInstagram}
                            alt="instagram"
                            className={styles.hoverImage}
                            />
                        </div>
                    </div>
                    <Link to="/login">
                    <div className={styles.loginButton}>
                        <img
                        src={login}
                        alt="Login"
                        className={styles.login}
                        />
                        <img
                        src={loginHover}
                        alt="Login"
                        className={styles.loginHover}
                        />
                    </div>
                    </Link>
                    <Link to="/cart">
                    <div className={styles.cartButton}>
                        <img
                        src={shop}
                        alt="Shopping Cart"
                        className={styles.cart}
                        />
                        <img
                        src={shopHover}
                        alt="Shopping Cart"
                        className={styles.cartHover}
                        />
                    </div>
                    </Link>
                </div>
            ) : (
                // Mobile Version
                <div className={styles.mobileWrapper}>
                    <div id="mobileWrapper" className={styles.mobileIcon} onClick={handleMobileMenu}>
                        <img
                        id="mobile"
                        src={closedMenu}
                        alt="menu"
                        />
                    </div>
                </div>
            )}
            {showMenu && <Menu />}
            {showMobileMenu && <Menu />}
            </>
        );
    }

};

export default Header;