import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { UserProvider } from "./context/userContext";

import {
  HomeLayout,
  Landing,
  Home,
  Order,
  Cart,
  Checkout,
  Events,
  Gallery,
  News,
  Contact,
  Login,
  LoginSuccess,
  Register,
  UserDetails,
} from "./pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "landing",
        element: <Landing />,
      },
      {
        path: "order_online",
        element: <Order />,
      },
      {
        path: "cart",
        element: <Cart />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "special_events",
        element: <Events />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "login/success", // 新增路由
        element: <LoginSuccess />,
      },
      {
        path: "user_details",  // 新增 UserHome 路由
        element: <UserDetails />,
      },
      // {
      //   path: "register",
      //   element: <Register />,
      // },
    ],
  },
]);

function App() {
  return (
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  );
}

export default App;
