import { useWindowScrollPositions } from '../../hooks/useWindowScrollPositions';
import { useIsMobile } from '../../hooks/useIsMobile';

import {
    Header,
    Banner,
    About,
    Reviews,
    Float,
    WhyChoose,
    Environment,
    Map,
    Footer,
} from "./components";

const Landing = () => {
    const { scrollY } = useWindowScrollPositions();
    return (
        <>
        <Header />
        <Banner />
        <About />
        <Reviews />
        <WhyChoose />
        <Environment />
        <Map />
        <Footer />
        {
            !useIsMobile() && scrollY > 150 ? <Float /> : null
        }
        {
            useIsMobile() && scrollY > 840 ? <Float /> : null
        }
        </>
    );
};

export default Landing;