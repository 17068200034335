import styles from "./Footer.module.scss";
import { useLocation } from "react-router-dom";

const Footer = () => {

    const location = useLocation();
    const isLandingPage = location.pathname === "/landing";
    const isLoginPage = location.pathname === "/login";
    const isRegisterPage = location.pathname === "/register";

    if(!isLandingPage && !isLoginPage && !isRegisterPage){
        return (
            <>
            <div className={styles.footerWrapper}>
                <div className={styles.text}>
                    <p>2024 © Copyright. TekoAI. All Rights Reserved.</p>
                    <p>Designed & Developed by <span>TekoAI</span></p>
                </div>
            </div>
            </>
        );
    }

};

export default Footer;