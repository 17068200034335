import { Scroll } from "../../components";

import {
    Banner,
} from "./components";

const Event = () => {

    return (
        <>
        <Banner />
        <Scroll />
        </>
    );

};

export default Event;